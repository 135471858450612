import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

class MerchantLandingFooter extends Component {
    render() {
        const { t } = this.props;
        const currentYear = new Date().getFullYear();

        return (
            <section className="merchant-landing-footer">
                <Container>
                    <Row>
                        <Col>
                            <p>
                                <a target="_blank" rel="noopener noreferrer" href="/privacy-policy">
                                    {t('merchantLanding.privacyPolicy')}
                                </a> 
                                <span> | </span> 
                                <a target="_blank" rel="noopener noreferrer" href="/terms-and-conditions">
                                    {t('merchantLanding.termsAndConditions')}
                                </a> 
                                <span> | </span> 
                                {t('merchantLanding.copyright', { year: currentYear })} 
                                <span> | </span> 
                                {t('merchantLanding.allRightsReserved')}
                            </p>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(MerchantLandingFooter);
