import React from 'react'

const CountTitle = ({title, subtitle}) => {
  return (
    <div className="flex align-items-center count-title justify-content-center">
        <div className="lh-1-2 px-2 poppins v2-font-54 v2-tl-font-48 v2-tp-font-40 v2-m-font-32 medium blue-text-11">{title}</div>
        <div className="lh-1-2 px-2 poppins v2-font-14 v2-tl-font-13 v2-m-font-11 bold dark-text-2 mw-140 break-word v2-m-break-word-unset">{subtitle}</div>
    </div>
  )
}

export default CountTitle