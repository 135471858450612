import React, { useState } from 'react';

import MerchantNFTItem from './MerchantNFTItem';
import MerchantCheckoutModal from '../merchant-landing/MerchantCheckoutModal';

import { Service } from '../../providers/Services';
import { toast } from 'react-toastify';

import {record} from '../../data/merchantNFTs';
import MerchantStripeModal from './MerchantStripeModal';
import MerchantCryptoConfirmationModal from '../merchant-landing/MerchantCryptoConfirmationModal';

const MerchantNFTLists = () => {

    const [selectedNFT, setSelectedNFT] = useState(null);
    const [checkoutModal, setCheckoutModal] = useState(null);
    const [cryptoConfirmationModal,setCryptoConfirmationModal] = useState(false);
    const [stripeModal,setStripeModal] = useState(false);

    // Handle Checkout Modal Confirmation
    const handleCheckoutModal = (e, status) => {
      setCheckoutModal(status)
    }

    // Handle Buy Now
    const handleBuyNow = (e, id) => {
      setSelectedNFT(id)
      setCheckoutModal(true)
    }

    // Pay Cash Stripe
    const handlePayCash = (e) => {
      e.preventDefault();
        // this.setState({ paymentLoader: true })
        Service.createPaymentIntent({nft: selectedNFT, payment_mode:1})
        .then(res => {
            // Start Timer
            // this.startTimer();
            // this.setState({clientSecret:res.clientSecret,isCheckout:true,modal:false})
            // console.log('ccssP:::::: ',res.chargeUrl)
            window.location.href = res.chargeUrl;
        })
        .catch(err => {
            toast.error('NFT Already Sold or Is In Progress, Please Try Again Later!');
            // this.setState({ paymentLoader: false })
            // this.setState({ cryptoConfirmationModal: false })
            // Close the checkout modal
            setCheckoutModal(false)
        })
    }

    // Handle Crypto Modal Confirmation
    const handleCryptoModalConfirmation = (e, status) => {
      e.preventDefault();
      // Close the confirmation modal
      setCheckoutModal(false)
      setCryptoConfirmationModal(status)
    }

    // Handle Pay Crypto
    const handlePayCrypto = (e) => {
        e.preventDefault();
        // this.setState({ paymentLoader: true })
        Service.createPaymentIntent({nft: selectedNFT, payment_mode:1})
        .then(res => {
            // Start Timer
            // this.startTimer();
            // this.setState({clientSecret:res.clientSecret,isCheckout:true,modal:false})
            console.log('ccssP:::::: ',res.chargeUrl)
            window.location.href = res.chargeUrl;
        })
        .catch(err => {
            toast.error('NFT Already Sold or Is In Progress, Please Try Again Later!');
            // this.setState({ paymentLoader: false })
            // this.setState({ showCryptoModalConfirmation: false })
            // Close the pay crypto confirmation
            setCryptoConfirmationModal(false)
        })
    }

    return (
        <>
          <div className="merchant-nft-lists">
              {record.map((item) => (
                  <MerchantNFTItem 
                      key={item.id} 
                      data={item} 
                      isActive={selectedNFT === item.id} 
                      setSelectedNFT={setSelectedNFT} 
                      handleBuyNow={handleBuyNow}
                  />
              ))}
          </div>
          
          {/* Merchant Confirmation Modal */}
          <MerchantCheckoutModal 
              show={checkoutModal} 
              setModal={handleCheckoutModal} 
              onCheckout={handlePayCash} 
              onCheckoutCryptoConfirmation={handleCryptoModalConfirmation}
              selectedNFT={selectedNFT}
          />

          {/* Stripe Payment Modal */}
          {/* <MerchantStripeModal isOpen={stripeModal} handleClose={setStripeModal} clientSecret={clientSecret} nftId={selectedNFT} time={300} /> */}

          {/* Checkout Crypto Modal */}
          <MerchantCryptoConfirmationModal 
              show={cryptoConfirmationModal} 
              setModal={handleCryptoModalConfirmation}
              onCheckout={handlePayCrypto}
          />
        </>
    );
};

export default MerchantNFTLists;
