import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import UploadPhotosInput from './UploadPhotosInput'

const UploadPhotos = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Upload Photos'} />

        <div className="v2-mt-1-25">
          <UploadPhotosInput title="Main Photo" maxFiles={1} />
        </div>

        <div className="v2-mt-1">
          <UploadPhotosInput title="3-4 More Photos" maxFiles={4} />
        </div>
        
    </div>
  )
}

export default UploadPhotos