import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

// Images
import IconUploadPhoto from '../../assets/img/merchant-onboarding/icon-upload-photos.png'

const UploadPhotosInput = ({ title, maxFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: maxFiles,
    onDrop: acceptedFiles => console.log(acceptedFiles),
  });

  return (
    <div
      {...getRootProps()}
      className="upload-photos-wrap"
    >
      <input {...getInputProps()} />

      <div className="flex align-items-center">
        <div>
            <img src={IconUploadPhoto} />
        </div>
        <p className="title">
            Drag & Drop
            or <strong>Browse</strong>
        </p>
      </div>

      <p className="v2-mt-1 subtitle">
        {title}
      </p>
    </div>
  );
};

export default UploadPhotosInput;