import React from 'react'

import '../assets/css/MerchantOnBoarding.css'
import { Col, Row } from 'reactstrap'
import BasicInfo from '../components/merchant-onboarding/BasicInfo'
import LocationDetails from '../components/merchant-onboarding/LocationDetails'
import ContactInfo from '../components/merchant-onboarding/ContactInfo'
import SocialMediaAccounts from '../components/merchant-onboarding/SocialMediaAccounts'
import UploadPhotos from '../components/merchant-onboarding/UploadPhotos'
import UploadLogo from '../components/merchant-onboarding/UploadLogo'
import SetupBusinessHours from '../components/merchant-onboarding/SetupBusinessHours'
import SelectCategory from '../components/merchant-onboarding/SelectCategory'

// Images
import IconArrowRight from '../assets/img/merchant-onboarding/icon-arrow-right.png'
import ImageUpload from '../components/merchant-onboarding/ImageUpload'

const MerchantOnBoarding = () => {
  return (
    <div className="merchant-on-boarding">
        <section>
            <div className="container">
                <div className="merchant-on-boarding-white-box">
                    <Row>
                        <Col>
                            <div className="merchant-on-boarding-header">
                                <h1 className="poppins v2-font-27 v2-tl-font-24 v2-m-font-20 semi-bold blue-text-12 relative z-index-2">Directory Profile</h1>
                            </div>
                        </Col>
                    </Row>

                    <div class="merchant-on-boarding-body">
                        <Row>
                            <Col sm={6}>
                                <BasicInfo />
                            </Col>

                            <Col sm={6} className="v2-tp-mt-2">
                                <LocationDetails />

                                <div className="v2-mt-3 v2-m-mt-2">
                                    <ContactInfo />
                                </div>
                            </Col>
                        </Row>

                        <Row className="v2-mt-2-5">
                            <Col sm={6}>
                                <SocialMediaAccounts />
                            </Col>

                            <Col sm={6}>
                                <Row>
                                    <Col sm={6} className="v2-tp-mt-2">
                                        <UploadPhotos />
                                    </Col>

                                    <Col sm={6} className="v2-tp-mt-2">
                                        <UploadLogo />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>

                        <Row className="v2-mt-3">
                            <Col>
                                <SetupBusinessHours />
                            </Col>
                        </Row>

                        <Row className="v2-mt-3">
                            <Col>
                                <SelectCategory />
                            </Col>
                        </Row>

                        <Row className="v2-mt-4 v2-m-mt-2">
                            <Col>
                                <div className="text-center">
                                    <button className="directory-submit-btn">Submit <img src={IconArrowRight} /></button>
                                </div>
                            </Col>
                        </Row>
                    </div>

                </div>
            </div>
        </section>
    </div>
  )
}

export default MerchantOnBoarding