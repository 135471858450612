import React from 'react'

// CSS
import '../assets/css/MerchantNFT.css'

// Components
import MerchantNFTHeader from '../components/merchant-nft/MerchantNFTHeader'
import MerchantNFTSteps from '../components/merchant-nft/MerchantNFTSteps'
import MerchantNFTBody from '../components/merchant-nft/MerchantNFTBody'

const MerchantNFT = () => {
  return (
    <div className="merchant-nft-main">
        <MerchantNFTHeader />

        <MerchantNFTSteps />

        <MerchantNFTBody />
    </div>
  )
}

export default MerchantNFT