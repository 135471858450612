import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import DirectoryInputText from './DirectoryInputText'
import { Col, Row } from 'reactstrap'

const ContactInfo = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Contact Info'} />

        <Row className="v2-mt-1">
            <Col sm={6}>
                <DirectoryInputText title={'Website URL'} />
            </Col>

            <Col sm={6} className="v2-m-mt-1">
                <DirectoryInputText title={'Phone Number'} />
            </Col>
        </Row>
    </div>
  )
}

export default ContactInfo