import React from 'react'

const BusinessHourItem = ({id}) => {
  
    const days = [
        'sun',
        'mon',
        'tue',
        'wed',
        'thu',
        'fri',
        'sat'
    ]

    const openingTime = [
        '9:00 AM',
        '10:00 AM',
        '11:00 AM',
        '12:00 PM',
        '1:00 PM'
    ]

    const closingTime = [
        '1:00 PM',
        '2:00 PM',
        '3:00 PM',
        '4:00 PM',
        '5:00 PM',
    ]

  return (
    <div className="business-hour-item">
        <div className='left'>
            <label>Opens at</label>
            <select>
                { openingTime.map((item, index) => (
                    <option>{item}</option>
                )) }
            </select>
        </div>

        <div className="mid">
            <label>Closes at</label>
            <select>
                { closingTime.map((item, index) => (
                    <option>{item}</option>
                )) }
            </select>
        </div>

        <div className="right">
            <div className="business-hour-item-days-wrap">
                { days.map((item, index)=>(
                    <div className="business-hour-item-days">
                        <label for={id + item}>
                            <input type='checkbox' id={id + item} />
                            {item}
                        </label>
                    </div>
                )) }
            </div>
        </div>
    </div>
  )
}

export default BusinessHourItem