import React from 'react'
import ImgStar from '../../assets/img/merchant-landing/img-review-star.png'

const ReviewItem = ({img, content, title, location}) => {
  return (
    <div className="text-center merchant-landing-review-item">
        <div className="img"><img src={img} /></div>
        <div className="content poppins v2-font-14 light dark-text-13 text-center v2-mt-1">
            <p>{content}</p>
        </div>
        <div className="title poppins v2-font-14 bold dark-text-7 v2-mt-0-5">{title}</div>
        { location && <div className="location poppins v2-font-14 medium dark-text-7">{location}</div>}
        <div className="star v2-mt-0-5">
            <img  width={135} src={ImgStar} />
        </div>
    </div>
  )
}

export default ReviewItem