import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import DirectoryInputText from './DirectoryInputText'
import DirectoryTextArea from './DirectoryTextArea'

const BasicInfo = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Basic Info'} />

        <div className="v2-mt-1">
            <DirectoryInputText title={'Business Name'} />
        </div>

        <div className="v2-mt-1-5">
            <DirectoryTextArea title={'Business Description'} />
        </div>
    </div>
  )
}

export default BasicInfo