import React from "react";
import {
  PaymentElement,
  useStripe,useElements
} from "@stripe/react-stripe-js";

import { customConsole, WALLET_URL } from '../../constants/defaultValues'
import { toast } from "react-toastify";

class CheckoutFormClass extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
      isLoading: false,
    };
  }

  componentDidMount() {
    this.checkPaymentIntent();
  }

  checkPaymentIntent = () => {
    const { stripe } = this.props;
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }
  
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          this.setState({ message: "Payment succeeded!" });
          toast.error('Payment succeeded!')
          break;
        case "processing":
          customConsole.log('processing::: ')
          this.setState({ message: "Your payment is processing." });
          toast.error('Your payment is processing.')
          break;
        case "requires_payment_method":
          customConsole.log('failed::: ')
          this.setState({ message: "Your payment was not successful, please try again." });
          toast.error('Your payment was not successful, please try again.')
          break;
        default:
          customConsole.log('failed::: ')
          this.setState({ message: "Something went wrong." });
          toast.error('Something went wrong. Please reload the page.')
          break;
      }
    })
    .catch(error => {
      console.error('Error retrieving PaymentIntent:', error);
      toast.error('Something went wrong.')
    });
  };

  handleSubmit = async (e) => {
    e.preventDefault();

    const { stripe, elements } = this.props;

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    this.setState({ isLoading: true });

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        // return_url: `${window.location.origin}/en/nfts/${this.props.nftId}`,
        return_url: `${WALLET_URL}premium/profile/${this.props.nftId}`,
      },
    });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      this.setState({ message: error.message });
      toast.error(error.message)
      window.location.href = `${window.location.origin}/en/nfts/${this.props.nftId}`
    } else {
      this.setState({ message: "An unexpected error occurred." });
      toast.error("An unexpected error occurred. Please reload the page.")
      window.location.href = `${window.location.origin}/en/nfts/${this.props.nftId}`
    }

    this.setState({ isLoading: false });
  };

  render() {
    const { message, isLoading } = this.state;
    const { stripe, elements } = this.props;

    const paymentElementOptions = {
      layout: "tabs"
    }

    return (
      <form id="payment-form" className="stripe-form" onSubmit={this.handleSubmit}>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <button className="stripe-button" disabled={isLoading || !stripe || !elements} id="submit">
          <span id="button-text">
            {isLoading ? <div className="spinner" id="spinner"></div> : "Pay now"}
          </span>
        </button>
        {/* Show any error or success messages */}
        {/* {message && <div id="payment-message">{message}</div>} */}
      </form>
    );
  }
}

// Wrapper functional component to use hooks
function MerchantStripeForm({nftId}) {
  const stripe = useStripe();
  const elements = useElements();

  return <CheckoutFormClass stripe={stripe} elements={elements} nftId={nftId}/>;
}

export default MerchantStripeForm;