import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Components
import ImgText from './ImgText';

// Images
import Img01 from '../../assets/img/merchant-landing/img-reason-01.jpg';
import Img02 from '../../assets/img/merchant-landing/img-reason-02.png';
import Img03 from '../../assets/img/merchant-landing/img-reason-03.png';
import Img04 from '../../assets/img/merchant-landing/img-reason-04.png';
import Img05 from '../../assets/img/merchant-landing/img-reason-05.png';

class MerchantLandingSection3 extends Component {
    render() {
        const { t } = this.props;

        return (
            <section className="v2-pb-3 merchant-landing-3">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="v2-mt-12 v2-tl-mt-10 v2-tp-mt-8 v2-m-mt-6 text-center poppins v2-font-37 v2-tl-font-33 v2-tp-font-28 v2-m-font-24 bold dark-text-7">
                                {t('merchantLanding.topReasonsTitle')}
                            </h2>
                        </Col>
                    </Row>

                    <div className="v2-mt-5 v2-m-mt-4">
                        <ImgText number={1} title={t('merchantLanding.reason1.title')} img={Img01} imgWidth={788} layout='full'>
                            <li>{t('merchantLanding.reason1.point1')}</li>
                            <li>{t('merchantLanding.reason1.point2')}</li>
                            <li>{t('merchantLanding.reason1.point3')}</li>
                        </ImgText>
                    </div>

                    <div className="v2-mt-6 v2-m-mt-5">
                        <ImgText number={2} title={t('merchantLanding.reason2.title')} img={Img02} imgWidth={425}>
                            <li>{t('merchantLanding.reason2.point1')}</li>
                            <li>{t('merchantLanding.reason2.point2')}</li>
                            <li>{t('merchantLanding.reason2.point3')}</li>
                        </ImgText>
                    </div>

                    <div className="v2-mt-6 v2-m-mt-5">
                        <ImgText number={3} title={t('merchantLanding.reason3.title')} img={Img03} imgWidth={297} direction='right'>
                            <li>{t('merchantLanding.reason3.point1')}</li>
                            <li>{t('merchantLanding.reason3.point2')}</li>
                            <li>{t('merchantLanding.reason3.point3')}</li>
                        </ImgText>
                    </div>

                    <div className="v2-mt-6 v2-m-mt-5">
                        <ImgText number={4} title={t('merchantLanding.reason4.title')} img={Img04} imgWidth={303}>
                            <li>{t('merchantLanding.reason4.point1')}</li>
                            <li>{t('merchantLanding.reason4.point2')}</li>
                            <li>{t('merchantLanding.reason4.point3')}</li>
                        </ImgText>
                    </div>

                    <div className="v2-mt-6 v2-m-mt-5">
                        <ImgText number={5} title={t('merchantLanding.reason5.title')} img={Img05} imgWidth={279} direction='right'>
                            <li className="no-icon">{t('merchantLanding.reason5.point1')}</li>
                        </ImgText>
                    </div>

                    <Row>
                        <Col>
                            <div className="v2-mt-6 v2-m-mt-4 text-center">
                                <a href="#" className="merchant-landing-btn">{t('merchantLanding.joinNow')}</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(MerchantLandingSection3);