import React from 'react'

const DirectoryCheckboxLists = ({options}) => {
  return (
    <ul className="directory-checkbox-lists">
        {options.map((item, index) => (
            <li>
                <label for={item}><input type="checkbox" id={item} /> {item}</label>
            </li>
        ))}
    </ul>
  )
}

export default DirectoryCheckboxLists