import React from 'react';
import IconCheckbox from '../../assets/img/merchant-nft/icon-checkbox.png';
import IconCheckboxActive from '../../assets/img/merchant-nft/icon-checkbox-active.png';

const MerchantNFTItem = ({ data, isActive, setSelectedNFT, handleBuyNow }) => {

    const handleClick = () => {
        setSelectedNFT(data.id); // Toggle selection
    };

    return (
        <div 
            className={`merchant-nft-item ${isActive ? 'active' : ''}`} 
            onClick={handleClick}
        >
            <div className="merchant-nft-img">
                <img 
                    src={isActive ? IconCheckboxActive : IconCheckbox} 
                    className="merchant-nft-item-checkbox" 
                    alt="Checkbox"
                />
                <img src={data.img} className="merchant-nft-img" alt={data.name} />
            </div>

            <div className="merchant-nft-footer">
                <div className="left">
                    <div className="merchant-nft-id">{data.id}</div>
                    <div className="merchant-nft-name">{data.name}</div>
                </div>
                <div className="right">
                    <button className="merchant-nft-btn" onClick={(e) => handleBuyNow(e, data.id)}>Buy Now</button>
                </div>
            </div>
        </div>
    );
};

export default MerchantNFTItem;