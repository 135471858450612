import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// User Providers
import { User } from '../../providers/User';

// Components
import LoginModal from '../modal/LoginModal';

// Images
import ImgPaid from '../../assets/img/merchant-landing/img-paid.png';
import ImgPlus from '../../assets/img/merchant-landing/img-plus-much-more.png';

class MerchantLandingSection4 extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selectedOption: '', // Default selected option
            errorMsg: null,
            loginModal: false,
            hasToken: false,
            checkoutModal: false,
            cryptoConfirmationModal: false,
            selectNFTModal: false,
            currentUrl: null
        };
    }

    handleSelection = (option) => {
        this.setState({ selectedOption: option });
    };

    handleStartNow = (e) => {
        e.preventDefault()
        
        // Show error message
        if (this.state.selectedOption == '') {
            this.setState({ errorMsg: 'Please select an option above.' })
            return false;
        }

        // Clear Error Message
        this.setState({ errorMsg: null })

        // Open Payment Modal if Paid is Selected
        if (this.state.selectedOption == 'paid') {
            // Check if logged in or not
            if (this.state.hasToken) {
                // Show Select NFT Modal
                // Redirect to Merchant NFT 
                window.location.href = '/merchant-nft'
            } else {
                // Show login
                this.setState({loginModal: true})
                this.setState({currentUrl: '/merchant-nft'})
            }
        } else {
            // Check if logged in or not
            if (this.state.hasToken) {
                // Redirect to Merchant on Boarding
                window.location.href = '/merchant-onboarding'
            } else {
                // Show login
                this.setState({loginModal: true})
                this.setState({currentUrl: '/merchant-onboarding'})
            }
        }
    }

    handleModalClose = () => {
        this.setState({ loginModal: false });
    }

    // ComponentDidMount
    componentDidMount() {
        User.getUser().then(data => {
            this.setState({ hasToken: true });
        }).catch(error => {
            this.setState({ hasToken: false });
        })
    }

    render() {
        const { t } = this.props;
        const { selectedOption, errorMsg, currentUrl } = this.state;

        return (
            <>
                <section className="v2-pt-14 v2-tl-pt-12 v2-tp-pt-10 v2-m-pt-6 v2-pb-8 v2-tl-pb-6 v2-tp-pb-4 merchant-landing-4">
                    <Container>
                        <Row>
                            <Col>
                                <h2 className="text-center poppins v2-font-37 v2-tl-font-33 v2-tp-font-28 v2-m-font-24 bold dark-text-7">
                                    Select Option
                                </h2>
                            </Col>
                        </Row>

                        <Row className="v2-mt-4 v2-m-mt-2 flex-flow-row">
                            <Col sm={6}>
                                <div 
                                    className={`merchant-white-box ${selectedOption === "free" ? "selected" : ""}`}
                                    onClick={() => this.handleSelection("free")}
                                >
                                    <h3 className="poppins v2-font-33 v2-tl-font-30 bold dark-text-7">
                                        <span className="merchant-underline">{t('merchantLanding.freeTitle')}</span>
                                    </h3>

                                    <p className="poppins v2-font-15 regular dark-text-7 v2-mt-1-5">
                                        {t('merchantLanding.freeDescription')}
                                    </p>

                                    <p className="poppins v2-font-15 bold dark-text-7 v2-mt-1-5">
                                        {t('merchantLanding.whatYouGet')}
                                    </p>

                                    <ul className="merchant-ul">
                                        <li>{t('merchantLanding.freePoint1')}</li>
                                        <li>{t('merchantLanding.freePoint2')}</li>
                                        <li>{t('merchantLanding.freePoint3')}</li>
                                        <li>{t('merchantLanding.freePoint4')}</li>
                                        <li>{t('merchantLanding.freePoint5')}</li>
                                        <li>{t('merchantLanding.freePoint6')}</li>
                                        <li>{t('merchantLanding.freePoint7')}</li>
                                    </ul>
                                </div>
                            </Col>

                            <Col sm={6} className="v2-m-mt-3">
                                <div 
                                    className={`merchant-white-box ${selectedOption === "paid" ? "selected" : ""}`} 
                                    onClick={() => this.handleSelection("paid")}
                                >
                                    <h3 className="poppins v2-font-33 v2-tl-font-30 bold dark-text-7">
                                        <span className="merchant-underline">{t('merchantLanding.paidTitle')}</span>
                                    </h3>

                                    <Row className="v2-mt-1-5 align-items-center">
                                        <Col sm={5}>
                                            <div>
                                                <img width={124} src={ImgPaid} alt="Paid" />
                                            </div>
                                        </Col>
                                        <Col sm={7}>
                                            <p className="poppins v2-font-50 v2-tl-font-45 bold pink-text-3 mb-0">
                                                {t('merchantLanding.paidPrice')}
                                            </p>
                                            <p className="poppins v2-font-15 regular dark-text-7">
                                                {t('merchantLanding.paidDescription')}
                                            </p>
                                        </Col>
                                    </Row>

                                    <p className="poppins v2-font-15 bold dark-text-7 v2-mt-2">
                                        {t('merchantLanding.paidIncludes')}
                                    </p>

                                    <ul className="v2-mt-1-5 merchant-ul">
                                        <li>{t('merchantLanding.paidPoint1')}</li>
                                        <li>{t('merchantLanding.paidPoint2')}</li>
                                        <li>{t('merchantLanding.paidPoint3')}</li>
                                        <li>{t('merchantLanding.paidPoint4')}</li>
                                    </ul>

                                    <div className="v2-mt-1 text-right">
                                        <img src={ImgPlus} width={213} alt="Plus More" />
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                
                                <div className="v2-mt-4 text-center">
                                    { errorMsg != '' && <p className="text-center red-text bold">{errorMsg}</p> }
                                    
                                    <a href="#" className="merchant-landing-btn" onClick={this.handleStartNow}>
                                        {t('merchantLanding.startNow')}
                                    </a>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>

                {/* Login Modal */}
                <LoginModal showModal={this.state.loginModal} handleModalClose={this.handleModalClose} redirectURL={currentUrl} />
            </>
        );
    }
}

export default withTranslation()(MerchantLandingSection4);