import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

// Image
import ImgOvato from '../../assets/img/merchant-landing/img-ovato-logo.png';
import TranslationDropdown from '../translation/TranslationDropdown';

class MerchantLandingBanner extends Component {
    render() {
        const { t } = this.props;

        return (
            <section className="merchant-landing-banner">
                <Container>
                    <Row>
                        <Col>
                            <div className="v2-mt-1">
                                <a href="/"><img src={ImgOvato} alt="Ovato" width={111} /></a>
                            </div>

                            <div className="mobile merchant-landing-translation">
                                <TranslationDropdown />
                            </div>
                        </Col>
                        <Col>
                            <div className="v2-mt-5 v2-m-mt-0 poppins v2-font-33 v2-tl-font-29 v2-m-font-24 semi-bold blue-text-10 lh-1-2 text-center">
                                {t('merchantLanding.helpingMerchants')}
                                <br />
                                <div className="pink-box">{t('merchantLanding.growBusiness')}</div>
                                <br />
                                {t('merchantLanding.usingCrypto')}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(MerchantLandingBanner);