import React from 'react'

import {customConsole, STRIPE_PROMISE_PUBLISABLE_KEY, toastStyle} from '../../constants/defaultValues'

import { Row, Col,Modal,ModalBody,ModalHeader } from 'reactstrap';

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import MerchantStripeForm from './MerchantStripeForm';

const stripePromise = loadStripe(STRIPE_PROMISE_PUBLISABLE_KEY);

const MerchantStripeModal = ({isOpen, handleClose, clientSecret, nftId, time}) => {
    const appearance = {
        theme: 'stripe',
    };

    const options = {
        clientSecret,
        appearance,
    };
  return (
    <Modal 
        isOpen={isOpen} 
    >
        <ModalBody>
            <div class="nft-checkout-modal-header">
                <h2 class="nft-checkout-modal-header-title">Checkout</h2>
                <p class="nft-checkout-modal-header-close">
                    <a href="#" onClick={(e) => handleClose(false)}><span class="material-symbols-outlined">close</span></a>
                </p>
            </div>
            <div className="App">
                <Elements options={options} stripe={stripePromise} >
                    <MerchantStripeForm nftId={nftId} />
                </Elements>
            </div>

            <div className='red-text nft-checkout-timer'>
                Checkout Before: {time}
            </div>
        </ModalBody>
    </Modal>
  )
}

export default MerchantStripeModal