import React from 'react'

const DirectoryInputText = ({title}) => {
  return (
    <div className="directory-input-text">
        <div>
            <label className="v2-font-15 v2-m-font-14 poppins regular blue-text-12" for={title}>{title}</label>
        </div>
        <div>
            <input type='text' id={title}></input>
        </div>
    </div>
  )
}

export default DirectoryInputText