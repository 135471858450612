import React, { Component } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Flag } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';

class TranslationDropdown extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dropdownOpen: false,
            langcode: localStorage.getItem('i18nextLng') || 'en',
            logout: true
        };
    }

    toggleDropdown = () => {
        this.setState({ dropdownOpen: !this.state.dropdownOpen });
    };

    changeLanguage = (lang) => {
        this.props.i18n.changeLanguage(lang);
        localStorage.setItem('i18nextLng', lang);
        this.setState({ langcode: lang, dropdownOpen: false });
    };

    render() {
        const { t } = this.props;
        const { dropdownOpen, langcode } = this.state;

        return (
            <Dropdown isOpen={dropdownOpen} size="sm" toggle={this.toggleDropdown}>
                <DropdownToggle tag="a" className="nav-link top_language_code" caret>
                    {langcode === 'ko' ? <><Flag name='kr' /><span className='top_language_code'>KO</span></> :
                    langcode === 'zh' ? <><Flag name='cn' /><span className='top_language_code'>ZH</span></> :
                    langcode === 'de' ? <><Flag name='de' /><span className='top_language_code'>DE</span></> :
                    langcode === 'ja' ? <><Flag name='jp' /><span className='top_language_code'>JP</span></> :
                    <><Flag name='us' /><span className='top_language_code'>EN</span></>}
                </DropdownToggle>
                <DropdownMenu className="top_language_drpdwn">
                    <DropdownItem header>{t('topbar.select_lang')}</DropdownItem>
                    <DropdownItem onClick={() => this.changeLanguage('en')}><Flag name='us' /> {t('topbar.english')}</DropdownItem>
                    <DropdownItem onClick={() => this.changeLanguage('zh')}><Flag name='cn' /> {t('topbar.chinese')}</DropdownItem>
                    <DropdownItem onClick={() => this.changeLanguage('de')}><Flag name='de' /> {t('topbar.german')}</DropdownItem>
                    <DropdownItem onClick={() => this.changeLanguage('ko')}><Flag name='kr' /> {t('topbar.korean')}</DropdownItem>
                    <DropdownItem onClick={() => this.changeLanguage('ja')}><Flag name='jp' /> {t('topbar.japanese')}</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        );
    }
}

export default withTranslation()(TranslationDropdown);
