import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import { Col, Row } from 'reactstrap'
import DirectoryCheckboxLists from './DirectoryCheckboxLists'

const SelectCategory = () => {
    const option1 = [
        'Restaurant & Café',
        'Health & Wellness',
        'Education & Training',
        'Professional Services'
    ]

    const option2 = [
        'Automotive',
        'Technology & IT Services',
        'Entertainment & Events',
        'Home Services'
    ]

    const option3 = [
        'Finance & Insurance',
        'Hospitality & Travel',
        'Real Estate',
        'Arts & Crafts'
    ]

    const option4 = [
        'Sports & Fitness',
        'Others'
    ]
  return (
    <div>
        <DirectorySectionTitle title={'Select Category'} />

        <Row className="v2-mt-2-5 v2-m-mt-1-5 v2-pl-3 v2-tp-pl-0">
            <Col sm={3}>
                <DirectoryCheckboxLists options={option1} />
            </Col>

            <Col sm={3}>
                <DirectoryCheckboxLists options={option2} />
            </Col>

            <Col sm={3}>
                <DirectoryCheckboxLists options={option3} />
            </Col>

            <Col sm={3}>
                <DirectoryCheckboxLists options={option4} />
            </Col>
        </Row>
    </div>
  )
}

export default SelectCategory