import React from 'react'

import { Container, Row, Col } from 'reactstrap'

const ImgText = ({number, title, img, imgWidth, children, direction='left', layout='half'}) => {
  return (
    <Row className={`align-items-center merchant-img-text ${direction} ${layout}`}>
        <Col className="left-col" sm={6}>
            <h3 className="title poppins v2-font-23 v2-tl-font-20 bold dark-text-7"><span className="number">{number}</span> {title}</h3>
            <ul className="ul">
                {children}
            </ul>
        </Col>
        <Col className="right-col" sm={6}>
            <div className="text-center v2-m-mt-1">
                <img src={img} width={imgWidth ? imgWidth : undefined} className="merchant-landing-img-text" />
            </div>
        </Col>
    </Row>
  )
}

export default ImgText