import React from 'react'

const MerchantNFTCategories = () => {
    const categories = [
        {title: 'Restaurant & Cafe'},
        {title: 'Health & Wellness'},
        {title: 'Entertainment & Events'},
        {title: 'Arts & Crafts'},
        {title: 'Automotive'},
        {title: 'Technology & IT Services'},
        {title: 'Real Estate'},
        {title: 'Finance & Insurance'},
        {title: 'Hospitality & Travel'},
        {title: 'Professional Services'},
        {title: 'Sports & Fitness'},
        {title: 'Education & Training'},
        {title: 'Home Services'},
        {title: 'Red Light'}
    ];
  return (
    <ul className="merchant-nft-categories">
        { categories.map((item, index) => (
            <li key={index}>
                <label>
                    <input type="radio" name="nft-categories" value={item.title} />
                    <span>
                        { item.title }
                    </span>
                </label>
            </li>
        )) }
    </ul>
  )
}

export default MerchantNFTCategories