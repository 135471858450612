import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import MerchantNFTStepBox from './MerchantNFTStepBox'

const MerchantNFTSteps = () => {
  return (
    <div className="v2-pb-4 merchant-nft-steps">
        <Container>
            <h2 className="poppins v2-font-23 v2-tp-font-20 semi-bold blue-text-12 text-uppercase text-center ls-10 v2-m-ls-4">Three easy steps</h2>

            <Row className="merchant-nft-step-box-wrap">
                <Col sm={4}>
                    <MerchantNFTStepBox number={1} text={'Select Category'} />
                </Col>

                <Col sm={4} className="v2-tp-mt-2">
                    <MerchantNFTStepBox number={2} text={'Select Art'} />
                </Col>

                <Col sm={4} className="v2-tp-mt-2">
                    <MerchantNFTStepBox number={3} text={'Pay Now'} />
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MerchantNFTSteps