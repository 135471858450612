import { ToastContainer } from 'react-toastify';
import { Widget, addResponseMessage, setQuickButtons, renderCustomComponent } from 'react-chat-widget';
import React, { Suspense, useEffect } from 'react';
import axios from 'axios';
import Markdown from 'markdown-to-jsx';
import 'react-chat-widget/lib/styles.css';
import './assets/css/Markdown.css';
import './assets/css/App.css';
import './assets/css/mediaquery.css';
import Route from './routes';
import { CHAT_BACKEND_URL } from './constants/defaultValues'; 
import imgOvatoMobile from "./assets/img/new-home-3/logo-ovato-mobile.png";

const MarkdownMessage = ({ text }) => {
  return (
    <div className="markdown-message">
      <Markdown
        options={{
          forceBlock: true,
          overrides: {
            a: {
              props: {
                target: "_blank",
                rel: "noopener noreferrer",
              },
            },
          },
        }}
      >
        {text}
      </Markdown>
    </div>
  );
};

const transform = (responseData) => {
  if (typeof responseData === "string") {
    return responseData;
  }

  try {
    if (
      responseData &&
      typeof responseData === "object" &&
      responseData.response
    ) {
      let message = responseData.response;
      const sources = responseData.sources || [];

      if (sources.length > 0) {
        message += "\n\n**Sources:**";
        sources.forEach((source, index) => {
          message += `\n${index + 1}. [${source.title}](${source.url})`;
        });
      }

      return message;
    } else {
      return JSON.stringify(responseData);
    }
  } catch (error) {
    console.error("Error in transform function:", error);
    return "Sorry, there was an error processing the response.";
  }
};
class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service
    console.error("Error caught by boundary:", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <div className="error-boundary-fallback">
          <h3>Something went wrong</h3>
          <p>We're sorry, but there was an error displaying this content.</p>
          <button onClick={() => this.setState({ hasError: false })}>
            Try Again
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}

const App = () => {
  useEffect(() => {
    addResponseMessage("hi! How can I help you ?");
  }, []);

  const handleNewUserMessage = async (newMessage) => {
    console.log(`New message incoming! ${newMessage}`);
    try {
      // Send the message to your backend using axios
      const response = await axios.post(`${CHAT_BACKEND_URL}/api/chat`, {
        message: newMessage,
      });

      console.log(response.data);

      // Instead of just adding text, render a custom component with Markdown
      const markdownContent = transform(response.data);
      renderCustomComponent(
        MarkdownMessage,
        { text: markdownContent },
        true // isBot parameter
      );
    } catch (error) {
      console.error("Error communicating with backend:", error);
      addResponseMessage("Sorry, there was an error processing your message.");
    }
  };

  const logo = require("./assets/img/ovato-logo-footer-new.png");

  return (
    <Suspense fallback="Loading...">
      <ToastContainer />
      <Route />
      <div className="WidgetApp">
        <ErrorBoundary>
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            title="Ovato AI chatbot"
            subtitle=""
            emojis={true}
            showBadge={true}
            autofocus={true}
            profileAvatar={logo}
          />
        </ErrorBoundary>
      </div>
    </Suspense>
  );
};
export default App;