import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import CountTitle from './CountTitle';

class MerchantLandingCount extends Component {
    render() {
        const { t } = this.props;

        return (
            <section className="merchant-landing-count">
                <Container>
                    <Row>
                        <Col sm={3}>
                            <CountTitle title={'3+'} subtitle={t('merchantLanding.yearsInIndustry')} />
                        </Col>

                        <Col sm={3}>
                            <CountTitle title={'2.7M+'} subtitle={t('merchantLanding.blocksSettled')} />
                        </Col>

                        <Col sm={3} className="v2-m-mt-1">
                            <CountTitle title={'30%'} subtitle={t('merchantLanding.annualIncrease')} />
                        </Col>

                        <Col sm={3} className="v2-m-mt-1">
                            <CountTitle title={'4.8'} subtitle={t('merchantLanding.appRatings')} />
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(MerchantLandingCount);