import ImgSample from '../assets/img/merchant-nft/img-sample-img-01.png';

const record = [
    { id: '#001', name: 'Name 01', img: ImgSample },
    { id: '#002', name: 'Name 02', img: ImgSample },
    { id: '#003', name: 'Name 03', img: ImgSample },
    { id: '#004', name: 'Name 04', img: ImgSample },
    { id: '#005', name: 'Name 05', img: ImgSample },
    { id: '#006', name: 'Name 06', img: ImgSample },
    { id: '#007', name: 'Name 07', img: ImgSample },
    { id: '#008', name: 'Name 08', img: ImgSample },
    { id: '#009', name: 'Name 09', img: ImgSample },
    { id: '#010', name: 'Name 10', img: ImgSample },
    { id: '#011', name: 'Name 11', img: ImgSample },
    { id: '#012', name: 'Name 12', img: ImgSample },
    { id: '#013', name: 'Name 13', img: ImgSample },
    { id: '#014', name: 'Name 14', img: ImgSample },
    { id: '#015', name: 'Name 15', img: ImgSample },
    { id: '#016', name: 'Name 16', img: ImgSample },
    { id: '#017', name: 'Name 17', img: ImgSample },
    { id: '#018', name: 'Name 18', img: ImgSample },
    { id: '#019', name: 'Name 19', img: ImgSample },
    { id: '#020', name: 'Name 20', img: ImgSample },
    { id: '#021', name: 'Name 21', img: ImgSample },
    { id: '#022', name: 'Name 22', img: ImgSample },
    { id: '#023', name: 'Name 23', img: ImgSample },
    { id: '#024', name: 'Name 24', img: ImgSample },
    { id: '#025', name: 'Name 25', img: ImgSample },
    { id: '#026', name: 'Name 26', img: ImgSample },
    { id: '#027', name: 'Name 27', img: ImgSample },
    { id: '#028', name: 'Name 28', img: ImgSample },
    { id: '#029', name: 'Name 29', img: ImgSample },
    { id: '#030', name: 'Name 30', img: ImgSample },
];

const getRecord = (id) => {
    const selectedNFT = record.find((item) => item.id === id);

    if (!selectedNFT) {
        console.log("No NFT found with this ID");
        return null;
    }

    return selectedNFT;
}

// Export both the record and the function
export { record, getRecord };