import React from 'react'

const DirectorySocialInput = ({img, title}) => {
  return (
    <div className="directory-social-input">
        <div className="left">
            <label for={title}>
                <img src={img} />
            </label>
        </div>
        <div className="right">
            <input type='text' id={title} />
        </div>
    </div>
  )
}

export default DirectorySocialInput