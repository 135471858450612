import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import UploadLogoInput from './UploadLogoInput'

const UploadLogo = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Upload Logo'} />

        <div className="v2-mt-1-25">
          <UploadLogoInput title="Supports: JPEG, JPG, PNG" maxFiles={1} />
        </div>
        
    </div>
  )
}

export default UploadLogo