import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

// Images
import IconUploadLogo from '../../assets/img/merchant-onboarding/icon-upload-logo.png'

const UploadLogoInput = ({ title, maxFiles }) => {
  const { getRootProps, getInputProps } = useDropzone({
    accept: "image/*",
    maxFiles: maxFiles,
    onDrop: acceptedFiles => console.log(acceptedFiles),
  });

  return (
    <div
      {...getRootProps()}
      className="upload-photos-wrap upload-photos-wrap-2"
    >
      <input {...getInputProps()} />

      <div>
            <img src={IconUploadLogo} />
    </div>

        <p className="v2-mt-1 v2-mb-0 title">
            Drag & Drop
            or <strong>Browse</strong>
        </p>

      <p className="v2-mb-0 v2-mt-0-25 subtitle">
        {title}
      </p>
    </div>
  );
};

export default UploadLogoInput;