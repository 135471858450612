import React from 'react'
import { Col, Container, Row } from 'reactstrap'
import MerchantNFTSelectCategory from './MerchantNFTSelectCategory'
import MerchantNFTArt from './MerchantNFTArt'

const MerchantNFTBody = () => {
  return (
    <div className="merchant-nft-body">
        <Container>
            <Row>
                <Col sm={3}>
                    <MerchantNFTSelectCategory />
                </Col>
                <Col sm={9}>
                    <div className="v2-tl-mt-4 v2-m-mt-2">
                        <MerchantNFTArt />
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}

export default MerchantNFTBody