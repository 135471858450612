import React from 'react'
import MerchantNFTCategories from './MerchantNFTCategories'

const MerchantNFTSelectCategory = () => {
  return (
    <div className="merchant-nft-select-category">
        <p className="poppins v2-font-16 semi-bold blue-text-12 v2-mb-0 v2-tl-text-center">Select Category</p>

        <div className="v2-mt-1-5">
            <MerchantNFTCategories />
        </div>
        
    </div>
  )
}

export default MerchantNFTSelectCategory