import React from 'react'

const MerchantNFTPagination = () => {
  return (
    <ul className="merchant-nft-pagination">
        <li><a href='#'>{'<'}</a></li>
        <li><a href='#' className="active">1</a></li>
        <li><a href='#'>2</a></li>
        <li><a href='#'>3</a></li>
        <li><a href='#'>4</a></li>
        <li><a href='#'>{'>'}</a></li>
    </ul>
  )
}

export default MerchantNFTPagination