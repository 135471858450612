import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import BusinessHourItem from './BusinessHourItem'

const SetupBusinessHours = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Setup Business Hours'} />

        <div className="v2-mt-2-5 v2-m-mt-1-5">
          <div>
            <BusinessHourItem id={1} />
          </div>
          
          <div className="v2-mt-1">
            <BusinessHourItem id={2} />
          </div>
        </div>

        <div className="v2-mt-1-5 text-center">
          <a href='#' className="add-more-business-hour-item">+ Add More Schedule</a>
        </div>
        
    </div>
  )
}

export default SetupBusinessHours