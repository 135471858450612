import React from 'react'

const MerchantNFTStepBox = ({number, text}) => {
  return (
    <div className="merchant-nft-step-box">
        <div className="number">{number}</div>
        <div className="text">{text}</div>
    </div>
  )
}

export default MerchantNFTStepBox