import React from 'react'
import { Row, Col } from 'reactstrap'

// Images
import Logo from '../../assets/img/merchant-nft/logo-ovato.png'

const MerchantNFTHeader = () => {
  return (
    <div className="merchant-nft-header">
        <Row>
            <Col>
                <div className="img-wrap">
                    <a href='#'>
                        <img src={Logo} alt="Ovato" width={96} />
                    </a>
                </div>

                <h1 className='v2-pl-2 v2-pr-2 poppins v2-font-32 v2-tl-font-28 v2-m-font-24 semi-bold white-text text-center v2-mb-0 v2-mt-3 v2-tp-mt-0'>Welcome To Merchant Wallet Collection</h1>
            </Col>
        </Row>
    </div>
  )
}

export default MerchantNFTHeader