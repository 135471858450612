import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import DirectoryInputText from './DirectoryInputText'
import { Col, Row } from 'reactstrap'

const LocationDetails = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Location Details'} />

        <Row className="v2-mt-1">
            <Col sm={6}>
                <DirectoryInputText title={'Business Name'} />
            </Col>

            <Col sm={6} className="v2-m-mt-1">
                <DirectoryInputText title={'City'} />
            </Col>
        </Row>

        <Row className="v2-mt-1-5">
            <Col sm={5}>
                <DirectoryInputText title={'State'} />
            </Col>

            <Col sm={4} className="v2-m-mt-1">
                <DirectoryInputText title={'Country'} />
            </Col>

            <Col sm={3} className="v2-m-mt-1">
                <DirectoryInputText title={'ZIP Code'} />
            </Col>
        </Row>
    </div>
  )
}

export default LocationDetails