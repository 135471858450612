import React from 'react'

const DirectorySectionTitle = ({title}) => {
  return (
    <div className="flex align-items-center merchant-onboarding-section-title">
        <span className="poppins v2-font-18 v2-m-font-16 medium blue-text-12">{title}</span>
    </div>
  )
}

export default DirectorySectionTitle