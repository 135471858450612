import React, { Component } from 'react';
import { Container, Row, Col, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Flag } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import TranslationDropdown from '../translation/TranslationDropdown';

class MerchantLandingHeader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            
        };
    }

    render() {
        const { t } = this.props;

        return (
            <section className="merchant-landing-header">
                <Container>
                    <Row>
                        <Col className="flex align-items-center justify-content-center">
                            <p className="py-2 ls-4 v2-tp-ls-1 v2-m-ls-0 text-center poppins v2-font-15 v2-tl-font-14 v2-m-font-8 bold white-text text-uppercase v2-mb-0">
                                {t('merchantLanding.easiest')} <span className="orange-text-2">{t('merchantLanding.trillion')}</span> {t('merchantLanding.cryptoEconomy')}
                            </p>
                            <div className="desktop">
                              <TranslationDropdown />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(MerchantLandingHeader);