import React from 'react'
import { Col, Row } from 'reactstrap'

import MerchantNFTPagination from './MerchantNFTPagination'
import MerchantNFTLists from './MerchantNFTLists'

const MerchantNFTArt = () => {
  return (
    <div className="merchant-nft-select-category">
        <Row>
            <Col>
                <p className="poppins v2-font-16 semi-bold blue-text-12 v2-mb-0">Select Art</p>
            </Col>

            <Col className="v2-m-mt-2">
                <MerchantNFTPagination />
            </Col>
        </Row>
        

        <div className="v2-mt-1-5">
            <MerchantNFTLists />
        </div>
        
    </div>
  )
}

export default MerchantNFTArt