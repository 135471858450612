import React from 'react'

import { Container, Row, Col } from 'reactstrap'

// IMG
import ImgVideo from '../../assets/img/merchant-landing/img-video.jpg'

const MerchantLandingVideo = () => {
  return (
    <section>
        <Container>
            <Row>
                <Col>
                    <div className="merchant-landing-video">
                        <img src={ImgVideo} />
                    </div>
                </Col>
            </Row>
        </Container>
    </section>
  )
}

export default MerchantLandingVideo