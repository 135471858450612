import React from 'react'
import DirectorySectionTitle from './DirectorySectionTitle'
import DirectoryInputText from './DirectoryInputText'
import { Col, Row } from 'reactstrap'
import DirectorySocialInput from './DirectorySocialInput'

// Images
import IconFB from '../../assets/img/merchant-onboarding/icon-fb.png'
import IconIG from '../../assets/img/merchant-onboarding/icon-ig.png'
import IconTwitter from '../../assets/img/merchant-onboarding/icon-twitter.png'
import IconYT from '../../assets/img/merchant-onboarding/icon-yt.png'

const SocialMediaAccounts = () => {
  return (
    <div>
        <DirectorySectionTitle title={'Social Media Accounts'} />

        <div className="v2-mt-1-5">
            <DirectorySocialInput title='fb' img={IconFB} />
        </div>

        <div className="v2-mt-1-5">
            <DirectorySocialInput title='ig' img={IconIG} />
        </div>
        
        <div className="v2-mt-1-5">
            <DirectorySocialInput title='twitter' img={IconTwitter} />
        </div>
        
        <div className="v2-mt-1-5">
            <DirectorySocialInput title='yt' img={IconYT} />
        </div>
        
    </div>
  )
}

export default SocialMediaAccounts