import React, { Component } from 'react';
import { Container, Row, Col } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import ReviewItem from './ReviewItem';

// IMG
import Img1 from '../../assets/img/merchant-landing/img-jeffery-r.png';
import Img2 from '../../assets/img/merchant-landing/img-javier-d.png';
import Img3 from '../../assets/img/merchant-landing/img-jessica-antoinette.png';

class MerchantLandingReviews extends Component {
    render() {
        const { t } = this.props;

        return (
            <section className="merchant-landing-reviews">
                <Container>
                    <Row>
                        <Col>
                            <h2 className="text-center poppins v2-font-37 v2-tl-font-33 v2-tp-font-28 v2-m-font-24 bold dark-text-7">
                                {t('merchantLanding.reviewsTitle')}
                            </h2>
                        </Col>
                    </Row>

                    <Row className="v2-mt-7">
                        <Col className="desktop" sm={4}>
                            <ReviewItem 
                                img={Img1} 
                                title={t('merchantLanding.review1.name')} 
                                content={t('merchantLanding.review1.content')} 
                                location={t('merchantLanding.review1.location')} 
                            />
                        </Col>
                        <Col sm={4}>
                            <ReviewItem 
                                img={Img2} 
                                title={t('merchantLanding.review2.name')} 
                                content={t('merchantLanding.review2.content')} 
                            />
                        </Col>
                        <Col className="desktop" sm={4}>
                            <ReviewItem 
                                img={Img3} 
                                title={t('merchantLanding.review3.name')} 
                                content={t('merchantLanding.review3.content')} 
                            />
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <div className="v2-mt-4 v2-m-mt-2 text-center">
                                <a href="#" className="merchant-landing-btn">{t('merchantLanding.joinNow')}</a>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    }
}

export default withTranslation()(MerchantLandingReviews);