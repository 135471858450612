import React from 'react'
import MerchantLandingHeader from '../components/merchant-landing/MerchantLandingHeader'
import MerchantLandingBanner from '../components/merchant-landing/MerchantLandingBanner'
import MerchantLandingSection2 from '../components/merchant-landing/MerchantLandingSection2'
import MerchantLandingSection3 from '../components/merchant-landing/MerchantLandingSection3'
import MerchantLandingSection4 from '../components/merchant-landing/MerchantLandingSection4'
import MerchantLandingFooter from '../components/merchant-landing/MerchantLandingFooter'

// CSS
import '../assets/css/MerchantLanding.css'

const MerchantLanding = () => {
  return (
    <div className="merchant-landing-main">
        <MerchantLandingHeader />

        <MerchantLandingBanner />

        <MerchantLandingSection2 />

        <MerchantLandingSection3 />

        <MerchantLandingSection4 />

        <MerchantLandingFooter />
    </div>
  )
}

export default MerchantLanding