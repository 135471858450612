import React from 'react'
import MerchantLandingCount from './MerchantLandingCount'
import MerchantLandingVideo from './MerchantLandingVideo'
import MerchantLandingReviews from './MerchantLandingReviews'

const MerchantLandingSection2 = () => {
  return (
    <section className="v2-pb-4 merchant-landing-2">

        <div className="v2-mt-2">
            <MerchantLandingCount />
        </div>
        
        <div className="v2-mt-4">
            <MerchantLandingVideo />
        </div>

        <div className="v2-mt-4">
            <MerchantLandingReviews />
        </div>

    </section>
  )
}

export default MerchantLandingSection2